import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Sidebar.module.scss';

// Source: https://getbootstrap.com/docs/5.0/examples/sidebars/
function Sidebar() {
  return (
    <>
      <li>
        <NavLink to="/partners" activeClassName="active" className="nav-link">
          <FontAwesomeIcon
            icon={['fas', 'warehouse']}
            className={styles.Icon}
          />
          Partners
        </NavLink>
      </li>
      <li>
        <NavLink to="/users" activeClassName="active" className="nav-link">
          <FontAwesomeIcon icon={['fas', 'users']} className={styles.Icon} />
          Users
        </NavLink>
      </li>
      <li>
        <NavLink to="/roles" activeClassName="active" className="nav-link">
          <FontAwesomeIcon icon={['fas', 'tasks']} className={styles.Icon} />
          Roles
        </NavLink>
      </li>
      <li>
        <NavLink to="/pods" activeClassName="active" className="nav-link">
          <FontAwesomeIcon
            icon={['fas', 'window-restore']}
            className={styles.Icon}
          />
          Pods
        </NavLink>
      </li>
      <li>
        <NavLink to="/status" activeClassName="active" className="nav-link">
          <FontAwesomeIcon icon={['fas', 'signal']} className={styles.Icon} />
          Status
        </NavLink>
      </li>
    </>
  );
}

export default Sidebar;
