import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loader } from '..';
import { PermissionsOptionsContext, RolesContext } from '../../providers';

const { usePermissionsOptions } = PermissionsOptionsContext;
const { useRoles } = RolesContext;

const permissionValues = [
  ['None'],
  ['View'],
  ['View & Edit', 'View and Edit'],
  ['Admin'],
];

function Permissions({ permissions, onChange, roles }) {
  const { permissionsOptions, isLoading } = usePermissionsOptions();

  // Once loaded, group permission keys by their group name
  const permissionsGroups = {};
  if (!isLoading) {
    Object.entries(permissions || {}).forEach(([key]) => {
      const { groupName } = permissionsOptions[key] || {};
      if (!(groupName in permissionsGroups)) {
        permissionsGroups[groupName] = [];
      }
      permissionsGroups[groupName].push(key);
    });
  }

  // Load permissions provided by additional roles
  const { getPermissions } = useRoles();
  const [permissionsFromRoles, setPermissionsFromRoles] = useState([]);
  useEffect(() => {
    (async () => {
      const loadedPermissions = Array.isArray(roles)
        ? await Promise.all(roles.map(({ id }) => getPermissions(id)))
        : [];
      setPermissionsFromRoles(loadedPermissions);
    })();
  }, [roles]);

  if (!permissions) {
    return (
      <div className="card text-white bg-danger bg-gradient">
        <div className="card-header">Error</div>
        <div className="card-body">
          <p className="card-text">Permissions object is missing</p>
        </div>
      </div>
    );
  }

  return isLoading ? (
    <Loader />
  ) : (
    <div>
      {Object.entries(permissionsGroups)
        .sort(([a], [b]) => (a < b ? -1 : 1))
        .map(([groupName, permissionKeys]) => {
          // Merge all permissions granted by roles
          let groupPermissionFromRoles = {};
          permissionsFromRoles.forEach((permissionsFromRole) => {
            groupPermissionFromRoles = {
              ...groupPermissionFromRoles,
              ...permissionsFromRole,
            };
          });

          return (
            <div className="card mb-5" key={groupName}>
              <div className="card-header">
                <h5 className="mb-0">{groupName}</h5>
              </div>
              <ul className="list-group list-group-flush">
                {permissionKeys
                  .sort((a, b) =>
                    (permissionsOptions[a] || {}).label <
                    (permissionsOptions[b] || {}).label
                      ? -1
                      : 1,
                  )
                  .map((key) => {
                    // Get the ASC values associated with each possible value (from "None"
                    // to "Admin")
                    const permissionsOption = permissionsOptions[key];
                    if (!permissionsOption) {
                      return <li key={key}>{`Unrecognized option: ${key}`}</li>;
                    }
                    const permissionsOptionsValues = permissionValues.map(
                      (value) => {
                        const item = Object.entries(
                          permissionsOption.options,
                        ).find((entry) => value.includes(entry[1]));
                        return item ? item[0] : undefined;
                      },
                    );
                    return (
                      <li
                        className="list-group-item d-flex align-items-center"
                        data-permission-name={key}
                        key={key}
                      >
                        <div className="me-auto">
                          <strong>
                            {permissionsOption.isGlobalPermission && (
                              <FontAwesomeIcon
                                icon={['fas', 'globe']}
                                className="me-2"
                                title="This is a global permission"
                              />
                            )}
                            {`${permissionsOption.label}: `}
                          </strong>
                          {permissionsOption.comment && (
                            <small className="d-block text-muted pe-5">
                              {permissionsOption.comment}
                            </small>
                          )}
                        </div>
                        <div className="btn-group text-nowrap">
                          {permissionValues.map((permissionValue, index) => {
                            const label = permissionValue[0];
                            const value = permissionsOptionsValues[index];
                            const classNames = [
                              'btn',
                              // Disable the buttons if there isn't an onChange handler
                              onChange ? '' : 'disabled',
                            ];
                            const hasPermission = permissions[key] === value;
                            const isGrantedThisPermissionByRoles =
                              key in groupPermissionFromRoles &&
                              groupPermissionFromRoles[key] === value;
                            const isGrantedOtherPermissionByRoles =
                              key in groupPermissionFromRoles &&
                              !isGrantedThisPermissionByRoles;
                            if (hasPermission) {
                              if (isGrantedOtherPermissionByRoles) {
                                classNames.push('btn-danger');
                              } else {
                                classNames.push(
                                  isGrantedThisPermissionByRoles
                                    ? 'btn-primary'
                                    : 'btn-dark',
                                );
                              }
                            } else {
                              classNames.push(
                                isGrantedThisPermissionByRoles
                                  ? 'btn-success'
                                  : 'btn-outline-dark',
                              );
                            }
                            if (!value) {
                              classNames.push('opacity-25');
                            }
                            return onChange ? (
                              <button
                                key={index}
                                className={classNames.join(' ')}
                                type="button"
                                disabled={!value}
                                onClick={() => {
                                  const updatedPermissions = {
                                    ...permissions,
                                    [key]: value,
                                  };
                                  onChange(updatedPermissions);
                                }}
                              >
                                {label}
                              </button>
                            ) : (
                              <span
                                key={label}
                                className={classNames.join(' ')}
                              >
                                {label}
                              </span>
                            );
                          })}
                        </div>
                        {!onChange ? null : (
                          <button
                            className="btn ms-2 me-n2 btn-outline-dark"
                            onClick={() => {
                              const updatedPermissions = { ...permissions };
                              delete updatedPermissions[key];
                              onChange(updatedPermissions);
                            }}
                          >
                            <FontAwesomeIcon icon={['fas', 'trash']} />
                          </button>
                        )}
                      </li>
                    );
                  })}
              </ul>
            </div>
          );
        })}
    </div>
  );
}

export default Permissions;
