module.exports = {
  // North America
  A2Q3Y263D00KWC: 'Brazil',
  A2EUQ1WTGCTBG2: 'Canada',
  A1AM78C64UM0Y8: 'Mexico',
  ATVPDKIKX0DER: 'United States',

  // Europe
  A3O7FHSIRK2OV3: 'United Arab Emirates (pending registration)',
  A2VIGQ35RCS4UG: 'United Arab Emirates (U.A.E.)',
  AMEN7PMS3EDWL: 'Belgium',
  A1PA6795UKMFR9: 'Germany',
  ARBP9OOSHTCHU: 'Egypt',
  A1RKKUPIHCS9HS: 'Spain',
  A13V1IB3VIYZZH: 'France',
  A1F83G8C2ARO7P: 'UK',
  A21TJRUUN4KGV: 'India',
  APJ6JRA9NG5V4: 'Italy',
  A1805IZSGTT6HS: 'Netherlands',
  A1C3SOZRARQ6R3: 'Poland',
  A17E79C6D8DWNP: 'Saudi Arabia',
  A2NODRKZP88ZB9: 'Sweden',
  A33AVAJ2PDY3EV: 'Turkey',

  // Far East
  A19VAU5U5O7RUS: 'Singapore',
  A39IBJ37TRP1C6: 'Australia',
  A1VC38T7YXB528: 'Japan',

  // Special (presumed to be under NA stack)
  A6W85IYQ5WB1C: 'Login with Amazon',
  A3BXB0YN3XH17H: 'Amazon Pay (Sandbox View)',
  AGWSWK15IEJJ7: 'Amazon Pay (Production View)',
  GLOBAL: 'Global',
};
