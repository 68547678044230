import React, { createContext, useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import ApiContext from './Api';

const { useApi } = ApiContext;

const PodsContext = createContext();

const initialState = {
  isLoading: true,
  pods: [],
};
const reducer = (state, action) => {
  let returnValue;
  switch (action.type) {
    case 'getting pods': {
      returnValue = {
        ...state,
        isLoading: true,
      };
      break;
    }
    case 'set pods': {
      returnValue = {
        ...state,
        isLoading: false,
        pods: action.pods,
      };
      break;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
  return returnValue;
};

function PodsProvider({ children }) {
  const { request } = useApi();
  const [state, dispatch] = useReducer(reducer, initialState);

  // Load pods from the API on provider mount
  const getPods = async (params) => {
    const { ConsistentRead } = params || {};
    dispatch({
      type: 'getting pods',
    });
    return request(
      `/pods${ConsistentRead ? '?ConsistentRead=true' : ''}`,
      undefined,
      true,
    ).then((data) =>
      dispatch({
        type: 'set pods',
        pods: data,
      }),
    );
  };
  useEffect(getPods, []);

  const addPod = async ({ id, name, description }) => {
    await request('/pods', {
      method: 'POST',
      body: JSON.stringify({
        ...(id ? { id } : undefined),
        name,
        description,
      }),
    });
    return getPods({ ConsistentRead: true });
  };

  // Prepare state object passed to consumers
  const value = {
    ...state,
    addPod,
  };

  return <PodsContext.Provider value={value}>{children}</PodsContext.Provider>;
}
PodsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const usePods = () => {
  const context = useContext(PodsContext);
  if (context === undefined) {
    throw new Error('usePods must be used within a PodsProvider');
  }
  return context;
};

export default {
  PodsProvider,
  usePods,
};
