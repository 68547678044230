import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

function Table({ className, filters, thead, tfoot, children, isLoading }) {
  return (
    <div
      className={`${styles.Table} ${filters ? styles.hasFiltersRow : ''} ${
        className || ''
      }`}
    >
      <div className={`row pb-2 ${styles.Filters}`}>{filters}</div>
      <table className="table table-striped table-hover">
        <thead>{thead}</thead>
        <tbody>
          {children}
          {isLoading && (
            <tr>
              <td colSpan="99" align="center">
                <FontAwesomeIcon icon={['fas', 'circle-notch']} spin />
              </td>
            </tr>
          )}
        </tbody>
        {tfoot && <tfoot>{tfoot}</tfoot>}
      </table>
    </div>
  );
}
Table.propTypes = {
  isLoading: PropTypes.bool,
};
Table.defaultProps = {
  isLoading: false,
};

export default Table;
