import { useOktaAuth } from '@okta/okta-react';
import Select, { Option } from 'rc-select';
import { RolesContext } from '../../providers';
import { oktaRoles } from '../../utils';

const { useRoles } = RolesContext;

function RolesSelect({ id, className, value, onChange }) {
  const { isLoading, roles } = useRoles();
  const { authState } = useOktaAuth();
  const isAdmin = authState.isRoleAllowed(oktaRoles.ADMIN);

  return (
    <Select
      id={id}
      className={className}
      mode="multiple"
      value={value}
      loading={isLoading}
      disabled={!isAdmin}
      onChange={onChange}
      aria-describedby="rolesHelp"
      dropdownStyle={{ zIndex: 1060 }}
      placeholder="No roles selected"
      // Search by the option label, not the key (in this case, the role id)
      filterOption={(inputValue, option) =>
        typeof option.children === 'string' &&
        option.children.toLowerCase().includes(inputValue.toLowerCase())
      }
      tagRender={(props) => {
        /*
        props: {
          closable: true
          disabled: undefined
          label: "User Admin"
          onClose: ƒ onClose(event)
          value: "9ad12bff-0171-47c6-9776-5e4c0abca1b6
        }
        */
        const { label, onClose } = props;
        return (
          <div
            className="badge bg-secondary me-1"
            style={{ userSelect: 'none' }}
            onClick={onClose}
          >
            {label}
            <span
              className="rc-select-selection-item-remove"
              aria-hidden="true"
            >
              <span className="rc-select-selection-item-remove-icon ms-1">
                ×
              </span>
            </span>
          </div>
        );
      }}
    >
      {roles.map((role) => (
        <Option key={role.id}>{role.name}</Option>
      ))}
    </Select>
  );
}

export default RolesSelect;
