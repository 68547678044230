import React, { createContext, useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import ApiContext from './Api';

const { useApi } = ApiContext;

const PermissionsOptionsContext = createContext();

const initialState = {
  isLoading: true,
  permissionsOptions: {},
};
const reducer = (state, action) => {
  let returnValue;
  switch (action.type) {
    case 'set permissions options': {
      returnValue = {
        ...state,
        isLoading: false,
        permissionsOptions: action.permissionsOptions,
      };
      break;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
  return returnValue;
};

function PermissionsOptionsProvider({ children }) {
  const { request } = useApi();
  const [state, dispatch] = useReducer(reducer, initialState);

  // Choose appropriate language from the available translations
  const t = (obj) => {
    const priority = ['en-US', 'en-CA', 'en-GB', 'en-AU'];
    for (const lang in priority) {
      if (lang in obj) {
        return obj[lang];
      }
    }
    const available = Object.keys(obj);
    return obj[available[0]];
  };

  // Load permissions from the API on provider mount
  useEffect(() => {
    request(`/permissions`, undefined, true).then((data) => {
      // Take array of permissions options, and convert it to an object with translation
      // strings selected
      const permissionsOptions = {};
      for (const item of data) {
        const options = {};
        for (const option of item.options) {
          options[option.value] = t(option.label);
        }
        permissionsOptions[item.name] = {
          groupName: t(item.groupName),
          label: t(item.label),
          ...(item.isGlobalPermission && { isGlobalPermission: true }),
          ...(item.comment && { comment: t(item.comment) }),
          options,
        };
      }
      dispatch({
        type: 'set permissions options',
        permissionsOptions,
      });
    });
  }, []);

  return (
    <PermissionsOptionsContext.Provider value={state}>
      {children}
    </PermissionsOptionsContext.Provider>
  );
}
PermissionsOptionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const usePermissionsOptions = () => {
  const context = useContext(PermissionsOptionsContext);
  if (context === undefined) {
    throw new Error(
      'usePermissionsOptions must be used within a PermissionsOptionsProvider',
    );
  }
  return context;
};

export default {
  PermissionsOptionsProvider,
  usePermissionsOptions,
};
