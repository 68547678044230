import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './index.module.scss';

function ButtonSpinner({ waiting }) {
  return (
    <span className={`${styles.Spinner} ${waiting ? styles.Waiting : ''}`}>
      <FontAwesomeIcon icon={['fas', 'circle-notch']} spin />
    </span>
  );
}

ButtonSpinner.propTypes = {
  waiting: PropTypes.bool.isRequired,
};

export default ButtonSpinner;
