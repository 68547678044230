import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const ThemeContext = React.createContext();

function ThemeProvider({ children }) {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  useEffect(() => {
    const isThemeClass = /^theme-/;
    document.body.classList.forEach((className) => {
      if (isThemeClass.test(className)) {
        document.body.classList.remove(className);
      }
    });
    document.body.classList.add(`theme-${theme}`);
    localStorage.setItem('theme', theme);
  }, [theme]);

  const value = { theme, setTheme };

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
}
ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useThemeState = () => {
  const context = React.useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useThemeState must be used within a ThemeProvider');
  }
  return context;
};

export default {
  ThemeProvider,
  useThemeState,
};
