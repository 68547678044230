function Select({ children, placeholder, defaultValue, value, onChange }) {
  return (
    <select
      className="form-control select-with-placeholder placeholder-active"
      defaultValue={defaultValue}
      value={value}
      onChange={(e) => {
        e.target.classList.toggle(
          'placeholder-active',
          e.target.selectedIndex === 0,
        );
        if (onChange) {
          onChange(e);
        }
      }}
    >
      {placeholder && <option value="">{placeholder}</option>}
      {children}
    </select>
  );
}

export default Select;
