import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOktaAuth } from '@okta/okta-react';
import { ThemeContext } from './providers';
import Sidebar from './Sidebar';
import './Navbar.bootstrap.scss';
import styles from './Navbar.module.scss';

const { useThemeState } = ThemeContext;

function Navbar() {
  const elementButton = useRef(null);
  const elementCollapse = useRef(null);

  const { theme, setTheme } = useThemeState();

  const { oktaAuth } = useOktaAuth();

  return (
    <nav
      className={`${styles.Nav} navbar navbar-expand-lg sticky-top navbar-dark`}
    >
      <div className="container-fluid">
        <Link
          className="navbar-brand me-auto"
          title="Speak, friend, and enter"
          to="/partners"
        >
          <span className="d-inline-block">
            <span className={styles.ThrasioLogo} />
            <span>Gatekeeper</span>
          </span>
          <FontAwesomeIcon icon={['fas', 'dungeon']} className="me-2" />
        </Link>
        <button
          ref={elementButton}
          className="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarScroll"
          aria-controls="navbarScroll"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => {
            const button = elementButton.current;
            const collapse = elementCollapse.current;
            if (!(button && collapse)) {
              return;
            }
            const open = button.getAttribute('aria-expanded') === 'false';
            if (open) {
              button.setAttribute('aria-expanded', 'true');
              button.className = 'navbar-toggler';
              collapse.classList.add('show');
            } else {
              button.setAttribute('aria-expanded', 'false');
              button.className = 'navbar-toggler collapsed';
              collapse.classList.remove('show');
            }
          }}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          ref={elementCollapse}
          id="navbarScroll"
          className="collapse navbar-collapse flex-shrink-1 flex-grow-0"
        >
          <div className="navbar-nav-scroll d-lg-none">
            <ul className="nav nav-pills flex-column me-auto mb-2 mb-lg-0">
              <Sidebar />
            </ul>
          </div>
          <div className="text-end pb-2 pb-lg-0">
            <button
              type="button"
              className="btn text-light"
              aria-label="Toggle light/dark theme"
              onClick={() => {
                setTheme(theme === 'light' ? 'dark' : 'light');
              }}
            >
              <FontAwesomeIcon icon={['fas', 'lightbulb']} className="ms-2" />
            </button>
            <button
              type="button"
              className="btn btn-light"
              onClick={async () => {
                await oktaAuth.signOut('/');
              }}
            >
              Sign Out
              <FontAwesomeIcon
                icon={['fas', 'sign-out-alt']}
                className="ms-2"
              />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
