import React, { createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import SessionContext from './Session';
import { globalConfig } from '../configuration/config';

const { useSessionState } = SessionContext;

const ApiContext = createContext();

const pendingRequests = [];
let cache = {};

function ApiProvider({ children }) {
  const { isLoggedIn, jwtToken } = useSessionState();

  const clearCache = () => (cache = {});

  // API request function that gets a single path and returns the JSON
  // TODO: Temporarily caching results during development, this may need tweaking longer-term
  const request = async (path, options = {}, ignoreCache = false) => {
    const method = options.method || 'GET';
    const headers = options.headers || {};
    if (!ignoreCache && method === 'GET' && path in cache) {
      return cache[path];
    }
    return fetch(`${globalConfig.get().apiUrl}${path}`, {
      // return fetch(`http://localhost:4566/restapis/xgmxxizx5j/dev/_user_request_${path}`, {
      ...options,
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        ...headers,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (method === 'GET') {
          cache[path] = data;
        } else {
          clearCache();
        }
        return data;
      });
  };

  // Promisify the requests if jwtToken is not available yet
  const value = {
    request: isLoggedIn
      ? request
      : async (path, options = {}) =>
          new Promise((resolve) => {
            pendingRequests.push({
              resolve,
              args: [path, options],
            });
          }),
    clearCache,
  };

  // Resolve any pending requests when jwtToken becomes available
  useEffect(() => {
    if (jwtToken) {
      pendingRequests.forEach(async ({ resolve, args }) =>
        resolve(request(...args)),
      );
    }
  }, [jwtToken]);

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
}
ApiProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useApi = () => {
  const context = React.useContext(ApiContext);
  if (context === undefined) {
    throw new Error('useApi must be used within a ApiProvider');
  }
  return context;
};

export default {
  ApiProvider,
  useApi,
};
