import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
function Loader({ large }) {
  return (
    <div className={`${styles.Loader} ${large ? styles.IsLarge : ''}`}>
      <FontAwesomeIcon icon={['fas', 'circle-notch']} spin />
    </div>
  );
}
Loader.propTypes = {
  large: PropTypes.bool,
};
Loader.defaultProps = {
  large: false,
};
export default Loader;
