import React, { useCallback, useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Select, { Option } from 'rc-select';
import { Loader } from 'components';
import { PodsContext } from 'providers';
import { oktaRoles } from 'utils';
import styles from './index.module.scss';

const { usePods } = PodsContext;

const stackLabels = {
  NA: 'North America',
  EU: 'European Union',
  FE: 'Far East',
};
const stackIds = Object.keys(stackLabels);

function PodsSelect({ id, className = '', value, onChange }) {
  const { isLoading, pods } = usePods();
  const { authState } = useOktaAuth();
  const isAdmin = authState.isRoleAllowed(oktaRoles.ADMIN);

  // `value` can be an array pod IDs, but also include specific stacks. The value will
  // look something like:
  //   [
  //     "a0f7b45b-1944-45c3-9a78-141408011317",
  //     "910dbc7a-60ec-4450-be10-4f6ffc79f8bf|NA",
  //     "910dbc7a-60ec-4450-be10-4f6ffc79f8bf|FE",
  //   ]
  // Initially filter this list to only include the pod IDs. The user can add additional
  // pod IDs using a dropdown.
  const getJustPodIdsFromValue = useCallback(
    (v = []) => v.map((podId) => podId.split('|')[0]),
    [value],
  );
  const selectedPodIds = getJustPodIdsFromValue(value);
  const [selectedPods, setSelectedPods] = useState(
    Array.from(new Set(selectedPodIds)),
  );
  // Add new podIDs to this list automatically when the `value` param changes
  useEffect(() => {
    setSelectedPods((v) =>
      Array.from(new Set([...v, ...getJustPodIdsFromValue(value)])),
    );
  }, [value, getJustPodIdsFromValue]);

  return isLoading ? (
    <Loader />
  ) : (
    <div id={id} className={className}>
      <dl className={styles.Pods}>
        {pods
          .map((pod) => ({
            ...pod,
            selectedStacks: value.includes(pod.id)
              ? stackIds
              : stackIds.filter((stackId) =>
                  value.includes(`${pod.id}|${stackId}`),
                ),
          }))
          // .filter(({ selectedStacks }) => selectedStacks.length > 0)
          .filter(({ id }) => selectedPods.includes(id))
          .sort(({ name: a }, { name: b }) => (a < b ? -1 : 1))
          .map((pod) => (
            <div key={pod.id}>
              <dt>{pod.name}</dt>
              <dd>
                <ul>
                  {stackIds.map((stackId) => (
                    <li key={stackId}>
                      <div className="form-check">
                        <input
                          id={`${pod.id}-${stackId}`}
                          className="form-check-input"
                          type="checkbox"
                          value={`${pod.id}|${stackId}`}
                          disabled={!isAdmin}
                          defaultChecked={pod.selectedStacks.includes(stackId)}
                          onChange={({
                            target: { checked, value: checkboxValue },
                          }) => {
                            let newValue = [...value];
                            // We need to handle situations where the pod value does not
                            // include a stackId. This historical value indicates that
                            // the user should be added to all stacks.
                            const isWholePodSelected = value.includes(pod.id);
                            if (isWholePodSelected && !checked) {
                              // Remove this entire pod ID from the value
                              newValue = newValue.filter(
                                (podId) => podId !== pod.id,
                              );
                              // Add values for the other stacks into the value
                              stackIds
                                .map((x) => `${pod.id}|${x}`)
                                .filter((x) => x !== checkboxValue)
                                .forEach((x) => newValue.push(x));
                            } else if (!checked) {
                              // Remove pod|stack value
                              newValue = newValue.filter(
                                (podId) => podId !== checkboxValue,
                              );
                            } else if (checked) {
                              // Add pod|stack value
                              newValue.push(checkboxValue);
                            }
                            onChange(newValue);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`${pod.id}-${stackId}`}
                        >
                          {stackId}: {stackLabels[stackId]}
                        </label>
                      </div>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
          ))}
      </dl>
      {isAdmin && (
        <Select
          showSearch
          autoClearSearchValue
          value={null}
          className="w-100"
          placeholder="Select additional pod"
          loading={isLoading}
          optionFilterProp="title"
          onSelect={(selectedValue) => {
            onChange([...value, selectedValue]);
          }}
        >
          {Object.values(pods)
            .filter(({ id }) => !selectedPodIds.includes(id))
            .sort(({ name: a }, { name: b }) => (a < b ? -1 : 1))
            .map((pod) => (
              <Option key={pod.id} value={pod.id} title={pod.name}>
                {pod.name}
              </Option>
            ))}
        </Select>
      )}
    </div>
  );
}

export default PodsSelect;
