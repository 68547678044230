import { Link } from 'react-router-dom';

function PermissionsHelp({ showColorHint = false }) {
  return (
    <div id="rolesHelp" className="form-text">
      <p className="mb-1">
        Toggle roles to affect the user&rsquo;s permissions listed below. Roles
        later in the list take precedence over permissions set by previous
        roles.
      </p>
      {showColorHint && (
        <p className="mb-1">
          In the permissions table shown below, roles shown in gray are not
          managed by any role. Permissions shown in blue are managed by a role,
          and permissions shown in green will be set during the next scraping.
        </p>
      )}
      <p>
        If a role doesn&rsquo;t manage the permission you need to adjust,{' '}
        <Link to="/roles">create a new role or edit an existing role</Link>.
      </p>
    </div>
  );
}

export default PermissionsHelp;
