import PropTypes from 'prop-types';

function downloadFile({ data, filename, type }) {
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(new Blob([data], { type }));
  a.setAttribute('download', filename);
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
}

downloadFile.propTypes = {
  data: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  type: PropTypes.string,
};
downloadFile.defaultProps = {
  type: 'text/plain;charset=utf-8',
};

export default downloadFile;
