import React, { Suspense, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { SecureRoute, LoginCallback } from '@okta/okta-react';
import { Loader } from './components';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import styles from './App.module.scss';

const Partner = lazy(() => import('./pages/Partner'));
const Partners = lazy(() => import('./pages/Partners'));
const Permissions = lazy(() => import('./pages/Permissions'));
const PodEdit = lazy(() => import('./pages/PodEdit'));
const Pods = lazy(() => import('./pages/Pods'));
const RoleEdit = lazy(() => import('./pages/RoleEdit'));
const Roles = lazy(() => import('./pages/Roles'));
const Status = lazy(() => import('./pages/Status'));
const User = lazy(() => import('./pages/User'));
const Users = lazy(() => import('./pages/Users'));

function App() {
  return (
    <div className={styles.App}>
      <Navbar />
      <div className="container-fluid px-0 m-0">
        <div className="row w-100 m-0">
          <div className={`col ${styles.Sidebar} d-none d-lg-block`}>
            <hr />
            <ul className="nav nav-pills flex-column mb-auto">
              <Sidebar />
            </ul>
          </div>
          <div className={`${styles.Divider} d-none d-lg-block`} />
          <main className="px-4 py-3 col">
            <Suspense fallback={<Loader large />}>
              <Switch>
                <Route path="/login/callback" component={LoginCallback} />
                <SecureRoute exact path="/status/:subpage">
                  <Status />
                </SecureRoute>
                <Redirect from="/status" to="/status/system/" />
                <SecureRoute exact path="/partners">
                  <Partners />
                </SecureRoute>
                <SecureRoute exact path="/partners/:id/:subpage">
                  <Partner />
                </SecureRoute>
                <Redirect from="/partners/:id" to="/partners/:id/users" />
                <SecureRoute exact path="/roles">
                  <Roles />
                </SecureRoute>
                <SecureRoute exact path="/roles/create">
                  <RoleEdit />
                </SecureRoute>
                <SecureRoute exact path="/roles/:id">
                  <RoleEdit />
                </SecureRoute>
                <SecureRoute exact path="/pods">
                  <Pods />
                </SecureRoute>
                <SecureRoute exact path="/pods/create">
                  <PodEdit />
                </SecureRoute>
                <SecureRoute exact path="/pods/:id">
                  <PodEdit />
                </SecureRoute>
                <SecureRoute exact path="/users">
                  <Users />
                </SecureRoute>
                <SecureRoute exact path="/users/:id/:subpage">
                  <User />
                </SecureRoute>
                <SecureRoute exact path="/users/:id/:subpage/:partnerId">
                  <User />
                </SecureRoute>
                <SecureRoute
                  exact
                  path="/users/:id/:subpage/:partnerId/:marketplaceId"
                >
                  <Permissions />
                </SecureRoute>
                <Redirect from="/users/:id" to="/users/:id/partners/" />
                {/*
                    Redirect away from a route missing :marketplaceId to the base User page
                  <Redirect from="/users/:id/:partnerId" to="/users/:id" />
                  */}
                <Redirect from="/" to="/partners" />
              </Switch>
            </Suspense>
          </main>
        </div>
      </div>
    </div>
  );
}

export default App;
