import { useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';

function PreventNavigation({ shouldPrevent }) {
  const confirmMessage =
    'There are unsaved changes. Are you sure you want to leave this page?';

  useEffect(() => {
    if (shouldPrevent) {
      window.addEventListener('beforeunload', preventUnload);
    } else {
      window.removeEventListener('beforeunload', preventUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', preventUnload);
    };
  }, [shouldPrevent]);

  const preventUnload = (e) => {
    // eslint-disable-next-line no-alert
    if (!window.confirm(confirmMessage)) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  return <Prompt when={shouldPrevent} message={confirmMessage} />;
}

PreventNavigation.propTypes = {
  shouldPrevent: PropTypes.bool.isRequired,
};

export default PreventNavigation;
