import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';

const SessionStateContext = React.createContext();

function SessionProvider({ children }) {
  const { authState, oktaAuth } = useOktaAuth();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [jwtToken, setJwtToken] = useState();
  useEffect(() => {
    // TODO: See https://developer.okta.com/docs/guides/sign-into-spa/react/main/#refresh-tokens-and-spas for token refresh
    if (authState && authState.isAuthenticated) {
      setIsLoggedIn(true);
      setJwtToken(oktaAuth.getAccessToken());
    } else {
      setIsLoggedIn(false);
      setJwtToken(/* undefined */);
    }
  }, [authState]);

  const value = {
    isLoggedIn,
    jwtToken,
  };

  return (
    <SessionStateContext.Provider value={value}>
      {children}
    </SessionStateContext.Provider>
  );
}
SessionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useSessionState = () => {
  const context = React.useContext(SessionStateContext);
  if (context === undefined) {
    throw new Error('useSessionState must be used within a SessionProvider');
  }
  return context;
};

export default {
  SessionProvider,
  useSessionState,
};
