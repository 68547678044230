import React from 'react';
import { useHistory } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { globalConfig } from './configuration/config';

function OktaAuthentication({ children }) {
  const oktaAuth = new OktaAuth({
    clientId: globalConfig.get().oktaClientId,
    issuer: globalConfig.get().oktaIssuer,
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: ['openid', 'profile', 'email', 'groups'],
    pkce: true,
    disableHttpsCheck: true,
    transformAuthState: async (oktaAuth, authState) => {
      authState.isRoleAllowed = (roleType) => {
        const { groups = [] } = authState.accessToken.claims;
        return groups.includes(roleType);
      };
      return authState;
    },
  });

  const history = useHistory();

  return (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={async (_oktaAuth, originalUri) => {
        history.replace(
          toRelativeUrl(originalUri || '/', window.location.origin),
        );
      }}
    >
      {children}
    </Security>
  );
}

export default OktaAuthentication;
