import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { marketplaceNames } from '../utils';

function MarketplaceBadge({
  marketplaceId,
  color,
  disabled = false,
  children,
}) {
  let bgClassName;
  if (disabled) {
    bgClassName = 'bg-danger';
  } else if (color) {
    bgClassName = `bg-${color}`;
  } else {
    bgClassName = 'bg-secondary';
  }
  return (
    <span className={`badge ${bgClassName} me-1`}>
      {marketplaceId === 'GLOBAL' ? (
        <>
          <FontAwesomeIcon icon={['fas', 'globe']} className="me-2" />
          Global
        </>
      ) : (
        marketplaceNames[marketplaceId]
      )}
      {children}
    </span>
  );
}

export default MarketplaceBadge;
