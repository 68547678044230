import { stringify } from 'csv-stringify/sync';
import downloadFile from './downloadFile';

const downloadCsv = ({ columns, data, filename }) => {
  const output = stringify(data, {
    header: true,
    quoted: true,
    columns,
  });
  downloadFile({
    data: output,
    filename,
    type: 'text/csv;charset=utf-8',
  });
};

export default downloadCsv;
